import React from 'react'
import { compose } from 'redux'
import Loadable from 'react-loadable'
import LoadableSpinner from '../../components/LoadableSpinner'
import withUser from '../../HOCs/withUser'

const AsyncTermsOfUse = Loadable({
  loader: () => import('./StvAndYou'),
  loading: () => (<LoadableSpinner centered />),
})

const enhanceTermsOfUse = compose(
  withUser
)

export default enhanceTermsOfUse(AsyncTermsOfUse)
