import React from 'react'
import PropTypes from 'prop-types'

const FacebookIcon = ({ width, height, title }) => (
  <svg width={width} height={height} viewBox="-44 -44 600 600">
    <title>{title}</title>
    <path fill="currentColor" d="M211.9 197.4h-36.7v59.9h36.7V433.1h70.5V256.5h49.2l5.2-59.1h-54.4c0 0 0-22.1 0-33.7 0-13.9 2.8-19.5 16.3-19.5 10.9 0 38.2 0 38.2 0V82.9c0 0-40.2 0-48.8 0 -52.5 0-76.1 23.1-76.1 67.3C211.9 188.8 211.9 197.4 211.9 197.4z" />
  </svg>
)

FacebookIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  title: PropTypes.string,
}

FacebookIcon.defaultProps = {
  width: 48,
  height: 48,
  title: 'Join STV on Facebook',
}

export default FacebookIcon
