import React, { Component, forwardRef } from 'react'
import PropTypes from 'prop-types'
import ButtonStyles from './styles'

class Button extends Component {
  constructor(props) {
    super(props)
    const { onClick } = this.props
    this.handleClick = onClick
  }

  resolveScopedStyles(scope) {
    return {
      className: scope.props.className,
      styles: scope.props.children,
    }
  }

  render() {
    const { variant, className, href } = this.props
    const scoped = this.resolveScopedStyles(
      <scope><style jsx>{ButtonStyles}</style></scope>
    )
    const classNames = [
      'button',
      scoped.className,
      ...(variant ? [variant] : []),
      ...(className ? [className] : []),
    ].filter(Boolean).join(' ')
    const Element = href ? 'a' : 'button'

    return (
      <Element
        id={this.props.id}
        ref={this.props.innerRef}
        type={Element === 'button' ? this.props.type : null}
        href={Element === 'a' ? href : null}
        onClick={this.handleClick}
        className={classNames}
        disabled={this.props.disabled || this.props.variant === 'blank'}
      >
        {this.props.children}
        { scoped.styles }
      </Element>
    )
  }
}

Button.propTypes = {
  id: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
  innerRef: PropTypes.shape({
    current: PropTypes.any,
  }),
  variant: PropTypes.oneOf(['primary-blue', 'secondary', 'primary-purple', 'secondary-purple', 'close', 'warning', 'blank']),
}

Button.defaultProps = {
  id: '',
  href: null,
  onClick: () => null,
  className: '',
  type: 'button',
  disabled: false,
  children: null,
  innerRef: { current: null },
  variant: null,
}

export default forwardRef((props, ref) => <Button innerRef={ref} {...props} />)
