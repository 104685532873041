import React, { Component } from 'react'
import ResetPassword from './ResetPassword'
import ResetPasswordSuccess from '../../components/ResetPasswordSuccess'
import { trackEvent } from '../../modules/dtm-helpers'

class ResetPasswordContainer extends Component {
  state = {
    page: '',
  }

  handleResetPassword = () => {
    this.setState({ page: 'ResetPasswordSuccess' })
    trackEvent('passwordResetForm', {})
  }

  render() {
    if (this.state.page === 'ResetPasswordSuccess') {
      return (
        <ResetPasswordSuccess />
      )
    }
    return (
      <ResetPassword
        onSuccess={this.handleResetPassword}
      />
    )
  }
}

export default ResetPasswordContainer
