import css from 'styled-jsx/css'
import { colors } from '../../../styles'

export default css`

  body {
    background-color: ${colors.baseBg};
  }

`
