
import css from 'styled-jsx/css'
import logo from './STVPlayerLogo.svg'

export default css`
  .StvLogo {
    display: block;
    height: 30px;
    width: 94.5px;
    width: auto;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    background-image: url(${logo});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }

  .nolink {
    cursor: default;
  }

  .StvLogo--center {
    margin: 0px auto 32px;
    height: 40px;
    width: auto;
  }

  @media
    (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 2dppx) {
    .StvLogo {
      background-image: url(${logo});
    }
  }
  @media
    (-webkit-min-device-pixel-ratio: 3),
    (min-resolution: 3dppx) {
    .StvLogo {
      background-image: url(${logo});
    }
  }
  @media (min-width: 900px) {
    .StvLogo {
      width: auto;
      margin-top: 0px;
      background-image: url(${logo});
    }

    .StvLogo--center {
      width: auto;
      background-image: url(${logo});
    }

  }
  @media
    (min-width: 900px) and (-webkit-min-device-pixel-ratio: 2),
    (min-width: 900px) and (min-resolution: 2dppx) {
    .StvLogo {
      background-image: url(${logo});
    }
  }
  @media
    (min-width: 900px) and (-webkit-min-device-pixel-ratio: 3),
    (min-width: 900px) and (min-resolution: 3dppx) {
    .StvLogo {
      background-image: url(${logo});
    }
  }
`
