import React from 'react'
import ErrorStyles from './style'
import { getCurrentDomain } from '../../modules/utils'

const ErrorPage = () => (
  <div className="dashboard-error">
    <div className="error-top">
      <h1>Sorry, we couldn&apos;t find that page</h1>
      <p>This might be because:</p>
      <ul>
        <li>The page has been unpublished</li>
        <li>There might be a typo in the url</li>
        <li>The page could have been deleted</li>
      </ul>
    </div>
    <div className="error-bottom">
      <h3>Where would you like to go?</h3>
      <p>Watch your favourite shows on <a href={`http://player.${getCurrentDomain()}`}>the STV Player</a>.</p>
      <p>Read the latest <a href={`//${getCurrentDomain()}/news`}>news</a>.</p>
      <p>Enter one of our <a href={`http://competitions.${getCurrentDomain()}`}>competitions</a>.</p>
      <p>Find details on how to <a href={`//${getCurrentDomain()}/info/contact`}>contact us</a>.</p>
    </div>
    <style jsx>{ErrorStyles}</style>
  </div>
)

export default ErrorPage
