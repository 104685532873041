import css from 'styled-jsx/css'
import { colors } from '../../styles'

export default css`
 
  .player-title{
    margin-bottom: 8px;
    margin-top: 8px;
    margin-left: calc(50% - 340px/2);
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    display: flex;
    align-items: center;
    width: 340px;
    height: 26px;
    color: #F6F6F6;
    }

  .logo {
    width: 35%;
    margin: 0 auto;
  }
  .updating {
    opacity: .7;
    pointer-events: none;
    position: relative;
  }
  h2 {
    font-size: 24px;
    font-weight: 400;
  }

  h3 {
    font-size: 22px;
  }

  h4 {
    font-size: 26px;
  }

  p, span {
    color: ${colors.whiteText};
    font-size: 14px;
  }
  .cardSubtitle{
    text-align: left;
    height: auto;
    margin: 8px 0px 8px 0px;
font-family: 'Source Sans Pro';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 16px;
color: #C4C4C4;
  }
  .player-plus-subtitle {
    margin: 0px;
    text-align: left;
    width: 340px;
    height: auto;
    margin-left: calc(50% - 340px/2);   
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 21px;
    color: #F6F6F6;
  }

  .grey-text {
    margin-left: calc(50% - 340px/2);
  }

button {
height: 20px;
font-family: 'Source Sans Pro';
font-style: normal;
font-weight: 400!important;
font-size: 18px;
line-height: 20px;
    border-radius: 8px;
    color: white;
    padding-bottom: 35px;
    border: none;
    background-color: ${colors.IconButtonPurple};
  }

  .button-wrap {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

.image-wrapper{
    width: 157px;
    height: 39px;
    margin: 0 auto;
    margin-bottom: 32px;
}


  .vip-title {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 67px;
    line-height: 90px;
    letter-spacing: -0.012em;
    color: ${colors.primaryText};
  }

  .vip-subtitle {
    font-weight: 400;
    font-size: 34px;
    line-height: 40px;
    color: ${colors.primaryText};
  }

  .vip-landing-text
  {
width: 343px;
height: auto;
margin-top:4px;
margin-left: calc(50% - 340px/2);
font-family: 'Source Sans Pro';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 20px;]
text-align: center;
color: #F6F6F6;
  }

  .logo-wrapper {
    display: flex;
    flex-direction: column;
    border-spacing: 10px;
    margin-bottom: 20px;
  }

  .logo-item {
    margin-top: 32px;
    text-align: center;
    flex-basis: 33%;
  }

  .gradient-title
  {
    margin: 4px 0px 4px 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    background: linear-gradient(90.04deg, #BD51EC 1.15%, #37EDAC 98.6%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  .Ts-Cs-container
  {
    margin-top: 64px;
    width: 340px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin-left: calc(50% - 340px/2);
    line-height: 15px;
    display: block;
    text-align: center;
    margin-bottom: 16x;
    color: ${colors.tertiaryText};
  }

  .button-wrapper {
    width: 100%;
  }

  .updating {
    opacity: .7;
    pointer-events: none;
    position: relative;
  }
  .waiting {
    pointer-events: none;
  }

  .success-modal{
    overflow: scroll;
    background-color: ${colors.baseBg};
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    max-width: 100%;
    height: 100%;
    margin: 0 auto;
    font-size: 18px;
  }
  
  .button-wrapper{
      display: flex;
      flex-direction: column;
      gap: 15px;
  }
  .success-modal .subTitle{
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #F6F6F6;
  }
  .success-modal-inner{
    margin: auto;
    height: auto;
    display: block;
    background: #212121;
    width: 100%;
  }
  
  .success-modal-contents {
    background-color: ${colors.baseBg};
    position: static;
    padding-top: 20%;
    margin: auto;
    height: 100%;
  }
  
  .logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
  }
  
  .terms-and-button-wrapper {
    position: absolute;
    padding-top: unset;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    top: unset;
    bottom: 40px;
    width: 90vw;
    margin: auto;
  }
  
  #success-terms {
    text-align: left;
  }
  .header-wrapper {
      width: 250px;
      margin: auto;
    }
  
  #success-header{
      font-family: 'Source Sans Pro';
      font-style: normal;
      font-weight: 600;
      font-size: 46px;
      line-height: 50px; 
      display: flex;
      align-items: center;
      text-align: center;     
      background: linear-gradient(81.24deg, #F544C6 29.81%, #FBA217 51.05%, #79CC32 74.63%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
  }
  
  .success-modal :global(.success-dismiss){
    background: #9916C7;
    height: 44px;
    border-radius: 8px;
    color: white;
    font-weight: 400;
    font-size: 18px;
    border: none;
    width: 100%;
    line-height: 15px;
  }
  
  .success-modal :global(.success-dismiss):hover {
    background: ${colors.darkPurple};
  }
  
  .success-modal :global(.success-dismiss-cross){
    background: unset;
  }
  
  @media (max-width: 767px){
    .success-modal-contents{
      padding-top: 16px;
      padding-bottom: 16px;  
  }
      .header-wrapper {
          width: 250px;
          margin: auto;
        }
        #success-header{
          display: block;
          margin-top: 24px;
        }
  
  .success-modal .subTitle{
    margin: 32px 0px 0px;
  }
  .terms-and-button-wrapper {
    padding-top: 197px;
  }
  }
  
  @media (min-width: 767px){
    .success-modal{
      width: 100%;
      height: 100%;
      background-color: #151515;
    }

    .grey-text {
      margin-top: 32px;
    }  
    
    .success-modal-inner{
      position: static;
      width: 720px;
    }
    
    .success-modal-contents {
      background-color: ${colors.elevatedBg};
      position: static;
      padding-top: 73px;
      padding-bottom: 32px;
      width: 80%;
      height: 100%;
    }
  
    .header-wrapper {
      width: 250px;
      margin: auto;
    }
    
    .logo {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
      
    .terms-and-button-wrapper {
      position: absolute;
      padding-top: unset;
      left: 0; 
      right: 0; 
      margin-left: auto; 
      margin-right: auto; 
      top: unset;
      bottom: 5%;
      width: 343px;
    }
    
    #success-terms {
      text-align: left;
    }
  
    #success-header{
      position: static;
      font-family: 'Source Sans Pro';
      font-style: normal;
      font-weight: 600;
      padding-top: 40%;
      text-align: center;
    }
  }
  
  @media (min-height: 1050px){
    .success-modal-inner{
     height: inherit;
    }
  }
  
  @media (min-width: 2000px){
    .success-modal{
      width: 100%;
      height: 100%;
      background-color: #151515;
    }
    
    .success-modal-contents {
      position: relative;
      top: 0;
      margin: auto;
    }
  
    .header-wrapper {
      width: 700px;
      margin: auto;
    }
    
    .logo {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    
    
    .terms-and-button-wrapper {
      width: 30vw;
      margin: auto;
    }
    
    #success-header{
      font-family: 'Source Sans Pro';
      font-style: normal;
      font-weight: 600;
      font-size: 124px;
      line-height: 132px;
      padding-top: 5vh;
    
      text-align: center;
    }
  
    .success-modal :global(.success-dismiss){
      height: 72px;
      font-size: 32px;
      border-radius: 8px;
      color: white;
      font-weight: 400;
      padding-bottom: 35px;
      border: none;
      width: 100%
    }
  }
  
  @media (min-width: 3000px){
    #success-header{
      font-size: 194px;
      line-height: 204px;
    }
  
    .terms-and-button-wrapper {
      width: 30vw;
      margin: auto;
    }
  
    .success-modal :global(.success-dismiss){
      height: 100px;
      font-size: 52px;
      border-radius: 8px;
      color: white;
      font-weight: 400;
      padding-bottom: 35px;
    }
  }

  @media (max-width: 767px) {

    .vip-title {
      font-size: 24px;
      line-height: 26px;
    }

    .vip-subtitle {
      font-size: 18px;
      line-height: 20px;
    }

    .logo-wrapper {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-bottom: 32px;
    }

    .logo-item {
      max-width: 252px;
    }
    
    .logo-item p {
      font-size: 14px;
      line-height: 16px;
      margin-top: 8px;
    }

    .Ts-Cs-container {
      margin-top: 32px;
      font-size: 12px;
      line-height: 15px;
      white-space: normal;
    }

`

