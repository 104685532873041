import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles'

const Spinner = ({
  width,
  height,
  centered,
  pplus,
}) => (
  <span
    className={`Spinner ${centered && 'centered'} ${pplus && 'pplus'}`}
    style={{
      width: `${width}px`,
      height: `${height}px`,
    }}
  >
    {centered && centeredStyles(width, height)}
    <style jsx>{styles}</style>
  </span>
)

const centeredStyles = (width, height) => (
  <style jsx>
    {`
    .centered {
      margin-left: -${width / 2}px;
      margin-top: -${height / 2}px;
      position: absolute;
      left: 50%;
      top: 50%;
    }
    .pplus{
      position: relative !important;
      margin-top: 0px !important; 
    }
    `}
  </style>
)

Spinner.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  centered: PropTypes.bool,
  pplus: PropTypes.bool,
}

Spinner.defaultProps = {
  width: 48,
  height: 48,
  centered: false,
  pplus: false,
}

export default Spinner
