import css from 'styled-jsx/css'
import { colors } from '../../styles'
import vipIcon from './STVPLAYER_VIP_NEG_RGB.png'
import vipIcon2 from './STVPLAYER_VIP_NEG_RGB_2x.png'
import vipIcon3 from './STVPLAYER_VIP_NEG_RGB_3x.png'
import vipIcon4 from './STVPLAYER_VIP_NEG_RGB_4.5x.png'

export default css`
  .account-summary {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;
    padding: 12px 0 12px 16px;
    border-bottom: 1px solid #F6F6F61A;
  }

  .account-summary.no-border {
    border-bottom: unset;
  }

  .avatar-container {
    height: 32px;
    width: 32px;
    font-size: 21px;
  }

  .account-details {
    display: flex;
    gap: 2px;
    flex-wrap: wrap;
    flex-direction: row;
  }

  .account-name {
    flex-basis: 100%;
  }

  .account-name p {
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0px;
    color: ${colors.primaryText};
    margin: 0;
    text-transform: capitalize;
  }

  .account-status {
    display: flex;
    gap: 6px;
    flex-direction: row;
    align-items: center;
  }

  .account-status .player-plus {
    display: flex;
    gap: 6px;
    align-items: center;
  }

  .account-status .player-plus.has-bullet::after {
    content: '';
    height: 1px;
    width: 1px;
    background: ${colors.primaryText};
    border-radius: 50%;
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .account-status p {
    color: ${colors.secondaryText};
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0px;
    margin: 0;
  }

  // VIP logo styles
  .vip {
    background-image: url(${vipIcon});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    width: 15px;
    height: 12px;
  }

  @media
    (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 2dppx) {
    .vip {
      background-image: url(${vipIcon2});
    }
  }
  @media
    (-webkit-min-device-pixel-ratio: 3),
    (min-resolution: 3dppx) {
    .vip {
      background-image: url(${vipIcon3});
    }
  }

  @media (min-width: 900px) {
    .vip {
      background-image: url(${vipIcon3});
    }
  }
  @media
    (min-width: 900px) and (-webkit-min-device-pixel-ratio: 2),
    (min-width: 900px) and (min-resolution: 2dppx) {
    .vip {
      background-image: url(${vipIcon3});
    }
  }
  @media
    (min-width: 900px) and (-webkit-min-device-pixel-ratio: 3),
    (min-width: 900px) and (min-resolution: 3dppx) {
    .vip {
      background-image: url(${vipIcon4});
    }
  }

  @media (max-width: 767px) {
    .account-summary {
      border-bottom: unset;
    }
  }
`
