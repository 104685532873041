import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles'
import { ONBOARDING_STEPS } from '../../constants/onboarding'

const StepCounter = ({ step, steps }) => (
  <div className="steps">
    <span className="steps-title">Step {step} of {steps}</span>
    <div className="counter">
      {
        [...Array(steps)].map((v, i) => <div className={`step${i + 1 <= step ? ' active-complete' : ''}`} />)
      }
    </div>
    <style jsx>{styles}</style>
  </div>
)

StepCounter.propTypes = {
  step: PropTypes.number.isRequired,
  steps: PropTypes.number,
}

StepCounter.defaultProps = {
  steps: ONBOARDING_STEPS,
}


export default StepCounter
