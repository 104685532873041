import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import withUser from '../../HOCs/withUser'
import HeaderLoginLink from './HeaderLoginLink'

const enhanceHeaderLoginLink = compose(
  withUser,
  withRouter
)

export default enhanceHeaderLoginLink(HeaderLoginLink)
