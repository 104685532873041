import cookies from 'js-cookie'
import { SET_USER, CLEAR_USER } from '../constants/action_types'

const getInitialState = () => ({})
// eslint-disable-next-line default-param-last
export default function user(state = getInitialState(), action) {
  const { type, payload } = action
  const vipOptIn = cookies.get('STV_VIP_OPTIN') === 'true'
  const modifiedPayload = { ...payload, vipOptIn }

  switch (type) {
    case SET_USER:

      return {
        ...modifiedPayload,
      }
    case CLEAR_USER:
      return getInitialState()
    default:
      return {
        ...state,
      }
  }
}
