import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'
import DashboardRouteStyles from './styles'
import PageFooter from '../../../components/PageFooter'
import HeaderMenu from '../../../components/HeaderMenu'

const DashboardRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <Fragment>
        <header className="PageHeader">
          <HeaderMenu />
        </header>
        <main>
          <Component {...props} />
        </main>
        <PageFooter />
        <style jsx global>{DashboardRouteStyles}</style>
      </Fragment>
    )}
  />
)

DashboardRoute.propTypes = {
  // eslint-disable-next-line react/require-default-props
  component: PropTypes.oneOfType([
    PropTypes.func.isRequired,
    PropTypes.object.isRequired,
  ]),
}

export default DashboardRoute
