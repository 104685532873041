import React from 'react'
import { compose } from 'redux'
import Loadable from 'react-loadable'
import LoadableSpinner from '../../components/LoadableSpinner'
import withUser from '../../HOCs/withUser'

const AsyncUpdateNewsletter = Loadable({
  loader: () => import('./UpdateNewsletter'),
  loading: () => (<LoadableSpinner centered />),
})

const enhanceUpdateNewsletter = compose(
  withUser
)

export default enhanceUpdateNewsletter(AsyncUpdateNewsletter)
