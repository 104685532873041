import css from 'styled-jsx/css'
import { colors } from '../../styles'

export default css`

.steps {
  text-align: left;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${colors.secondaryText};
  margin: 0px;
}

.steps-title {
  margin-bottom: 12px;
  display: block;
  font-size: 16px;
  font-weight: 400;
}

.counter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 8px;
}

.step {
  height: 3px;
  background: ${colors.ctvBg};
  border-radius: 8px;
  flex-grow: 0;
  flex: 1;
}

.step.active-complete {
  background: ${colors.lilac};
}
`
