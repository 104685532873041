import getCurrentDomain from '../getCurrentDomain'

const getLoggedOutUrl = (
  currentDomain = getCurrentDomain()
) => (
  currentDomain === 'my.stvqa.tv'
    ? '//stvqa.tv'
    : `//${currentDomain}`
)

export default getLoggedOutUrl
