import { getCurrentDomain } from '../../modules/utils'

const HOST = getCurrentDomain()

const stvLinks = {
  title: 'STV',
  show: 'phone',
  items: [
    {
      text: 'News',
      url: `https://${HOST}/news`,
    },
    {
      text: 'STV Appeal',
      url: `//www.${HOST}/appeal`,
    },
    {
      text: 'Careers',
      url: `http://www.stvplc.tv/careers`,
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    {
      text: 'Advertise with STV',
      url: 'https://www.stvcommercial.tv/',
    },
    {
      text: 'STV Group PLC',
      url: 'http://www.stvplc.tv/',
    },
  ],
}

const getMoreLinks = {
  title: 'Get More',
  show: 'phone',
  items: [
    {
      text: 'STV Player+',
      url: `https://player.${HOST}/player-plus`,
    },
    {
      text: 'TV Guide',
      url: `https://player.${HOST}/tvguide/`,
    },
    {
      text: 'How to watch',
      url: `http://support.stv.tv/support/solutions/articles/2000007702`,
    },
    {
      text: 'Competitions',
      url: `http://competitions.${HOST}`,
    },
  ],
}

const quickLinks = {
  title: 'Quick Links',
  show: 'phone',
  items: [
    {
      text: 'Parental controls',
      url: `https://player.${HOST}/settings`,
    },
    {
      text: 'Help & support',
      url: `http://support.stv.tv/support/home`,
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    {
      text: 'TV activation code',
      url: `https://my.${HOST}/activate`,
    },
    {
      text: 'Visually Signed',
      url: `https://player.${HOST}/categories/visually-signed`,
    },
    {
      text: 'Audio Described',
      url: `https://player.${HOST}/categories/audio-described`,
    },
  ],
}

const informationLinks = {
  title: 'Information',
  show: 'phone',
  items: [
    {
      text: 'About us',
      url: `//www.${HOST}/info/about`,
    },
    {
      text: 'Contact us',
      url: `//www.${HOST}/info/contact`,
    },
    {
      text: 'Privacy',
      url: `//my.${HOST}/terms-of-use/#privacy`,
    },
    {
      text: 'Terms of use',
      url: `//my.${HOST}/terms-of-use/`,
    },
    {
      text: 'Cookie policy',
      url: `//my.${HOST}/terms-of-use/#cookies`,
    },
  ],
}

const links = [
  stvLinks,
  quickLinks,
  getMoreLinks,
  informationLinks,
]

export default links
