
export function trackEvent(name, detail) {
  const events = {
    // Registration and Login
    directPageView: 'page_view',
    enterEmail: 'join_signin_form',
    formSubmitError: 'form_submit_error',
    enterPassword: 'password_form',
    passwordResetForm: 'password_reset_form',
    passwordReset: 'password_reset',
    personalDetails: 'persdetails',
    planSelect: 'p+plan',
    freeVip: 'vipsub',
    plusVip: 'vipsubp+',
    enterNewUserDetails: 'registration_start',
    newUserSuccess: 'registration_complete',
    logInSuccess: 'signin',
    logOutSuccess: 'logout',
    formSubmitFailed: 'form_submit_failed',
    formClose: 'form_close',
    // TV Link
    enterTvLinkCode: 'tv_link_code_form',
    tvLinkSuccess: 'tv_link_success',
    // Postcode Modal
    postcodeModalShown: 'postcode_check',
    // Marketing Opt Ins
    STVMarketing: 'stv_email_optin',
    TargetedAds: 'target_ad_optin',
    VIPCountMeIn: 'vip_count_me_in',
    VIPNotNow: 'vip_not_now',
    // Meta Pixel Events
    InitiateCheckout: 'initiate_checkout', // implemented
    Subscribe: 'subscribe', // implemented
    StartTrial: 'start_trial', // implemented
    CompleteRegistration: 'complete_registration', // implemented
    VIPSignUp: 'vip_sign_up', // implemented
    PplusNavUpgrade: 'pplus_nav_upgrade',
  }

  const directCallEvents = [
    'page_view',
    'join_signin_form',
    'form_submit_error',
    'password_form',
    'password_reset_form',
    'password_reset',
    'registration_start',
    'registration_complete',
    'signin',
    'logout',
    'form_submit_failed',
    'form_close',
    'tv_link_code_form',
    'tv_link_success',
    'postcode_check',
    'stv_email_optin',
    'target_ad_optin',
    'vip_count_me_in',
    'vip_not_now',
    'initiate_checkout',
    'subscribe',
    'start_trial',
    'complete_registration',
    'vip_sign_up',
    'pplus_nav_upgrade',
  ]

  if (!Object.keys(events).includes(name)) {
    return undefined
  }

  if (
    window._satellite
    && window._satellite.track
    && directCallEvents.includes(events[name])
  ) {
    window._satellite.track(events[name], detail || {})
    return undefined
  }
  return undefined
}

export const customEventTags = {
  formField: 'form_fields',
  postcodeProvided: 'postcode_provided',
}

function customEvent(name, detail = {}, bubbles = true) {
  const event = new CustomEvent(name, {
    detail,
    bubbles,
  })

  document.body.dispatchEvent(event)
}

export function adobeCustomEvent(event, detail, bubbles = true) {
  customEvent(event, { ...detail }, bubbles)
}
