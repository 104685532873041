import cookies from 'js-cookie'

const isFireTv = () => {
  const fireTvUrl = document?.location?.pathname?.includes('firetv')
  const cookieSet = (cookies.get('STV_SOURCE_FIRETV') === 'true')
  return fireTvUrl || cookieSet
}

const isGoogleTv = () => {
  const googleTvUrl = document?.location?.pathname?.includes('googletv')
  const cookieSet = (cookies.get('STV_SOURCE_GOOGLETV') === 'true')
  return googleTvUrl || cookieSet
}

const setGoogleTvCookie = () => {
  cookies.set('STV_SOURCE_GOOGLETV', 'true')
}

const setFireTvCookie = () => {
  cookies.set('STV_SOURCE_FIRETV', 'true')
}

const clearGoogleTvCookie = () => {
  cookies.remove('STV_SOURCE_GOOGLETV')
}
const clearFireTvCookie = () => {
  cookies.remove('STV_SOURCE_FIRETV')
}

export {
  isFireTv,
  setFireTvCookie,
  clearFireTvCookie,
  isGoogleTv,
  clearGoogleTvCookie,
  setGoogleTvCookie,
}
