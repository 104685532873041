/* eslint-disable indent */
// TODO - tests
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  CloseButton,
  ModalBody,
  ModalButton,
  ModalButtons,
  ModalHeader,
  ModalLayout,
} from './ModalParts'

import styles from './styles'

class Modal extends Component {
  modalRef = React.createRef(null)

  closeRef = React.createRef(null)

  constructor(props) {
    super(props)
    this.state = {
      labelRef: this.props.labelRef,
      descRef: this.props.descRef,
      focusOriginRef: this.props.focusOriginRef,
    }
  }

  componentDidMount() {
    window.addEventListener('keydown', this.escapeKeyListener)
    window.addEventListener('keydown', this.tabListener)
    this.lockScroll(true)
    const {
      labelRef,
      descRef,
      focusOriginRef,
      initFocusRef,
    } = this.props

    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      labelRef,
      descRef,
      focusOriginRef,
    }, () => {
      if (initFocusRef && initFocusRef.current) {
        initFocusRef.current.focus()
        return
      }
      if (this.closeRef && this.closeRef.current) this.closeRef.current.focus()
    })
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.escapeKeyListener)
    window.removeEventListener('keydown', this.tabListener)
    this.lockScroll(false)
  }

  getFocusables = () => {
    const focusables = Array.from(this.modalRef.current.querySelectorAll('*'))
      .filter(el => this.isFocusable(el))
    return {
      firstFocus: focusables[0],
      lastFocus: focusables[focusables.length - 1],
    }
  }

  handleClose = () => {
    if (this.state.focusOriginRef && this.state.focusOriginRef.current) {
      this.state.focusOriginRef.current.focus()
    }
    this.props.closeFn()
  }

  handleTab = (event) => {
    const { firstFocus, lastFocus } = this.getFocusables()
    if (event.shiftKey && document.activeElement === firstFocus) {
      event.preventDefault()
      lastFocus.focus()
    } else if (!event.shiftKey && document.activeElement === lastFocus) {
      event.preventDefault()
      firstFocus.focus()
    }
  }

  escapeKeyListener = (event) => {
    if (event.key === 'Escape' || event.which === 27) {
      this.handleClose()
    }
  }

  tabListener = (event) => {
    if (event.key === 'Tab' || event.which === 9) {
      this.handleTab(event)
    }
  }

  isFocusable = (element) => {
    if (element.tabIndex > 0 || (element.tabIndex === 0 && element.getAttribute('tabIndex') !== null)) {
      return true
    }

    if (element.disabled) {
      return false
    }

    switch (element.nodeName) {
      case 'A':
        return !!element.href && element.rel !== 'ignore'
      case 'INPUT':
        return element.type !== 'hidden' && element.type !== 'file'
      case 'BUTTON':
      case 'SELECT':
      case 'TEXTAREA':
        return true
      default:
        return false
    }
  }

  lockScroll = (enable) => {
    const body = document.querySelector('body')
    const appWrapper = document.getElementById('root')
    if (body && appWrapper) {
      if (enable) {
        body.style.cssText = 'position: fixed; overflow: hidden; width: 100%;'
        appWrapper.setAttribute('aria-hidden', 'true')
      } else {
        body.style.cssText = ''
        appWrapper.setAttribute('aria-hidden', 'false')
      }
    }
  }

  render() {
    const {
      children,
    } = this.props

    const {
      labelRef,
      descRef,
    } = this.state
    const closeButton = () => <CloseButton onClick={this.handleClose} ref={this.closeRef} />

    return (
      <div className="dialog-backdrop no-scroll">
        <div
          className={`modal ${this.props.className}`}
          id="alert_dialog"
          role="alertdialog"
          aria-modal="true"
          aria-labelledby={labelRef && labelRef.current ? labelRef.current.getAttribute('id') : 'no-id'}
          aria-describedby={descRef && descRef.current ? descRef.current.getAttribute('id') : 'no-id'}
          ref={this.modalRef}
        >
          {
           children({ close: this.handleClose, CloseButton: closeButton })
          }
        </div>
        <style jsx>{styles}</style>
      </div>
    )
  }
}

Modal.propTypes = {
  // Element that should receive initial focus.
  // close button will be focused if nothing provided
  initFocusRef: PropTypes.shape({
      current: PropTypes.any,
    }),
  // Label for the modal dialog, usually the header
  labelRef: PropTypes.shape({
    current: PropTypes.any,
  }),
  // description for the modal dialog, usually the first p
  descRef: PropTypes.shape({
    current: PropTypes.any,
  }),
  // the element that triggered the modal - focus will return here on close
  focusOriginRef: PropTypes.shape({
    current: PropTypes.any,
  }),
  className: PropTypes.string,
  // Children must be provided as a function that takes a "close" param
  children: PropTypes.func.isRequired,
  // how to close the modal, this will have focus management added and be
  // passed through to the children fn as "close" param
  closeFn: PropTypes.func,
}

Modal.defaultProps = {
  initFocusRef: null,
  focusOriginRef: null,
  labelRef: null,
  descRef: null,
  className: '',
  closeFn: () => {},
}

export {
  ModalBody,
  ModalButton,
  ModalButtons,
  ModalHeader,
  ModalLayout,
}
export default Modal
