import css from 'styled-jsx/css'
import { colors } from '../../../styles'

export default css`

  main {
    background-color: ${colors.primaryText};
    min-height: 40vh;
  }

  .PageHeader {
    width: 100%;
    max-width: 1920px;
  }

`
