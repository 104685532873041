import React, { useEffect, useState } from 'react'
import cookies from 'js-cookie'
import { useSelector } from 'react-redux'
import successModal from './styles'
import Modal from '../../components/Modal'
import Button from '../../components/Button'
import { loginSplashScreen } from '../../actions/PlayerPlusAPICall'
import { getCurrentDomain } from '../../modules/utils'
import loginService from '../../initLoginService'
import Error from '../../components/Error'
import setPageName from '../../utils/pageName'

const LoginSplashScreen = () => {
  const [title, setTitle] = useState('')
  const [terms, setTerms] = useState('')
  const [buttonText, setButtonText] = useState('')
  const [logo, setLogo] = useState('')
  const [logoAlt, setLogoAlt] = useState('')
  const [error, setError] = useState(false)
  const [subscriptionInfo, setSubscriptionInfo] = useState(null) // State for subscription info
  const myDomain = getCurrentDomain()
  const userData = useSelector(state => state.user)
  const cookie = cookies.get('STV_VIP_OPTIN')
  const isVIP = (cookie === 'true')
  useEffect(() => {
    const fetchSubscriptionInfo = async () => {
      try {
        const info = await loginService.getSubscriptionInfo(userData['custom:subscriptions'])
        setSubscriptionInfo(info)
      } catch (err) {
        throw new Error('Error fetching subscription info:', err)
      }
    }
    fetchSubscriptionInfo()
  }, [userData])
  useEffect(() => {
    if (subscriptionInfo) {
      if (subscriptionInfo.usedTrial) {
        setPageName({
          page: 'login',
          guid: 'p+upgrade',
        })
      } else {
        setPageName({
          page: 'login',
          guid: 'p+freetrial',
        })
      }
      const getModalData = async () => {
        const strapiData = await loginSplashScreen()
        if (strapiData !== null) {
          setTitle(strapiData?.Title)
          setTerms(strapiData?.Terms)
          subscriptionInfo.usedTrial ? setButtonText('Upgrade to STV Player+') : setButtonText(strapiData?.ButtonText)
          setLogo(strapiData?.Logo?.data?.attributes?.url)
          setLogoAlt(strapiData?.Logo?.data?.attributes?.alternativeText)
          cookies.set('STV_HAVE_SEEN', true, { expires: 120, domain: getCurrentDomain() })
        } else {
          setError(true)
        }
      }
      getModalData()
    }
  }, [subscriptionInfo])
  return (
    !error ? (
      <Modal>
        {() => (
          <div className="success-modal">
            <div className="success-modal-inner">
              <div className="success-modal-contents">
                <div className="image-wrapper">
                  <img className="logo" src={logo} alt={logoAlt} width={200} height={56} />
                </div>
                <div className="header-wrapper">
                  <h2 id="success-header">{title}</h2>
                  <p className="subTitle">Switch off the ads with STV Player+</p>
                </div>
                <div className="terms-and-button-wrapper">
                  <p id="success-terms">
                    {terms}
                  </p>
                  <div className="button-wrapper">
                    <Button
                      className="success-dismiss"
                      variant="primary-purple"
                      href="/plan"
                    >
                      {buttonText}
                    </Button>
                    <Button
                      className="success-secondary"
                      variant="secondary"
                      href={isVIP ? `https://player.${myDomain}` : '/player-vip-sub'}
                    >
                      Not right now
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <style jsx>{successModal}</style>
          </div>
        )}
      </Modal>
    ) : <Error />
  )
}
export default LoginSplashScreen
