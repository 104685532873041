import React, { useEffect } from 'react'
import StvLogo from '../StvLogo'
import { getCurrentDomain } from '../../modules/utils'
import setPageName from '../../utils/pageName'
import ResetPasswordSuccessStyles from './styles'

const ResetPasswordSuccess = () => {
  const currentDomain = getCurrentDomain()
  const closeUrl = `https://player.${currentDomain}`

  useEffect(() => {
    setPageName({
      page: 'login',
      guid: 'passwordchanged',
    })
  }, [])

  return (
    <div className="FormWrapper">
      <div className="content-wrapper">
        <div className="form-head">
          <StvLogo center />
        </div>
        <div className="form-content">
          <div className="ResetPasswordSuccess">
            <h2 className="big-heading">Let&apos;s get streaming!</h2>
            <h4 className="sub-heading"><span className="success-icon">*</span> Your password was changed.</h4>
            <div className="button-wrapper">
              <a href={closeUrl}>
                <button
                  type="button"
                  className="stv-form-button"
                >
                  Let's go
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{ResetPasswordSuccessStyles}</style>
    </div>
  )
}

export default ResetPasswordSuccess
