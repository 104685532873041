import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'
import PortalRouteStyles from './styles'

const PortalRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <Fragment>
        <main>
          <Component {...props} />
        </main>
        <style jsx global>{PortalRouteStyles}</style>
      </Fragment>
    )}
  />
)

PortalRoute.propTypes = {
  // eslint-disable-next-line react/require-default-props
  component: PropTypes.oneOfType([
    PropTypes.func.isRequired,
    PropTypes.object.isRequired,
  ]),
}

export default PortalRoute
