import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import App from './App'
import withUser from '../../HOCs/withUser'

const enhanceApp = compose(
  withRouter,
  withUser,
)

export default enhanceApp(App)
