import React from 'react'
import Loadable from 'react-loadable'
import LoadableSpinner from '../../components/LoadableSpinner'
import withAuthentication from '../../HOCs/withAuthentication'

const AsyncActivate = Loadable({
  loader: () => import('./Activate'),
  loading: () => (<LoadableSpinner centered />),
})

export default withAuthentication(AsyncActivate)
