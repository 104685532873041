import css from 'styled-jsx/css'
import { colors } from '../../styles'

export default css`
.button {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.25;
  display: block;
  border-radius: 4px;
  text-align: center;
  transition: background .5s ease-in-out;
  white-space: normal;
  padding: 15px 40px;
  cursor: pointer;
  text-decoration: none;
  transition: 0.2s;
}

.small {
  font-size: 12px;
  line-height: 1.25;
  display: block;
  border-radius: 5px;
  padding: 10px;
  transition: 0.2s;
}

.primary-purple {
  border-color: ${colors.textHoverPurple};
  background: ${colors.textHoverPurple};
  color: ${colors.primaryText};
}

.blank {
  border: none;
  background: ${colors.textInput};
  width: 100%;
  border-radius: 8px;
}

.secondary {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  background-color: transparent;
  color: ${colors.primaryText};
  border: 0px;
}

.secondary-purple {
  border-color: ${colors.whiteText};
  background: ${colors.whiteText};
  color: ${colors.purpleButtonText};
}

.primary-purple:focus,
.secondary-purple:focus {
  box-shadow:  0 0 2px 2px #DD96FF;
}

button.primary-purple {
  color: ${colors.primaryText};
  background-color: ${colors.IconButtonPurple};
  cursor: default;
}

button.primary-purple:hover:not(.inactive) {
  background-color: ${colors.darkPurple};
}
button.primary-purple.marketing-button {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  align-items: center;
  text-align: center;
  border: none;
  line-height: 10px;
  height: 44px;
  border-radius: 8px;
}
.close{
  width: 38px;
  height: 38px;
  line-height: 40px;
  position: absolute;
  top:13px;
  right: 14px;
  color: transparent;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  border-radius: 50%;
  background: rgba(0,0,0,.4);
  font-weight: 700;
  outline: 2px;
  cursor: pointer;
  padding: 0;
  border: 0;
}

.close:focus{
  box-shadow: 0 0 5px 2px ${colors.primaryText}
}

.close:after {
  font-size: 32px;
  content: '\00d7';
  color: ${colors.primaryText};
  text-indent: 0;
  position: absolute;
  top: -2px;
  display: block;
  width: 100%;
  font-weight: 300;
}

.warning {
  display: flex;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: ${colors.red};
  color:${colors.primaryText};
  text-align: center;
  font-size: 18px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

button.primary-purple.payment-button {
  border: none;
  width: 100%;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  border-radius: 8px;
}

@media (min-width: 767px){
  .close {
    width: 44px;
    height: 44px;
    line-height: 48px;
    border-radius: 50%;
  }

  .close:after {
    font-size: 36px;
    color: ${colors.primaryText};
  }
  button.primary-purple.payment-button {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    box-shadow: none;
    border: none;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    border-radius: 8px;
  }

  button.primary-purple.back-button {
    box-shadow: none;
    border: none;
  }
}

@media (max-width: 374px) {

  button.primary-purple.payment-button {
    font-size: 18px;
    line-height: 20px;
  }

  .button {
    padding: 12px 34px;
  }
}
`
