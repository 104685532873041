import css from 'styled-jsx/css'

export default css`
  @keyframes spinner {
    from {
        transform: rotate(0)
    }
    to {
        transform: rotate(359deg)
    }
  }

  .Spinner {
    content: url('/img/Spinner.png');
    animation: spinner .75s infinite cubic-bezier(.6,.2,0,.8);
  }
`
