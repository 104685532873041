import { trackEvent } from '../../modules/dtm-helpers'

export default function setPageName({
  page,
  guid,
}) {
  if (typeof window === 'undefined') return
  const pageName = getPageName({
    page,
    guid,
  })
  window.digitalData.page.pageInfo.pageName = pageName
  // Let's fire a pageview to adobe, delayed by 250ms to allow digitalData to update
  setTimeout(() => {
    trackEvent('directPageView', {})
  }, 250)
}

export function getPageName({
  page,
  guid,
}) {
  return [
    'mystv',
    page,
    guid,
  ].filter(item => Boolean(item)).join(':')
}
