import React from 'react'
import { compose } from 'redux'
import Loadable from 'react-loadable'
import LoadableSpinner from '../../components/LoadableSpinner'
import withAuthentication from '../../HOCs/withAuthentication'
import withUser from '../../HOCs/withUser'

const AsyncResetPassword = Loadable({
  loader: () => import('./ResetPasswordLink'),
  loading: () => (<LoadableSpinner centered />),
})

const enhancePrivacy = compose(
  withAuthentication,
  withUser
)

export default enhancePrivacy(AsyncResetPassword)
