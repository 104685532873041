import React from 'react'
import PropTypes from 'prop-types'

/**
 * select which environment you want to render on...
 * Caveat: this will not work with setting global variables in dangerously set script elements
 * within the document body as the component is effectively mounted / rendered again on the client
 * and removed.
 */

class SSRSwitch extends React.Component {
  constructor(props) {
    super(props)
    this.state.shouldRender = this.props.env === 'server'
  }

  state = {
    shouldRender: null,
  }

  componentDidMount() {
    // componentDidMount is only run on the client...
    this.setState({ shouldRender: this.props.env === 'client' })
  }

  render() {
    const { children } = this.props
    const { shouldRender } = this.state
    return shouldRender ? <span className="SSRSwitch">{children}</span> : null
  }
}

export default SSRSwitch

export const ServerOnly = ({ children }) => (
  <SSRSwitch env="server">{children}</SSRSwitch>
)

export const ClientOnly = ({ children }) => (
  <SSRSwitch env="client">{children}</SSRSwitch>
)

SSRSwitch.propTypes = {
  env: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

ServerOnly.propTypes = {
  children: PropTypes.node.isRequired,
}

ClientOnly.propTypes = {
  children: PropTypes.node.isRequired,
}
