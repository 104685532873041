import React from 'react'
import PropTypes from 'prop-types'
import Spinner from '../Spinner'

const LoadableSpinner = ({
  pastDelay,
  ...props
}) => (
  pastDelay
    ? <Spinner {...props} />
    : null
)

LoadableSpinner.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  pastDelay: PropTypes.bool,
  centered: PropTypes.bool,
}

LoadableSpinner.defaultProps = {
  width: 48,
  height: 48,
  centered: true,
  pastDelay: false,
}

export default LoadableSpinner
