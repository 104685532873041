import React from 'react'
import Loadable from 'react-loadable'
import LoadableSpinner from '../../components/LoadableSpinner'
import withAuthentication from '../../HOCs/withAuthentication'

const AsyncChangePassword = Loadable({
  loader: () => import('./ChangePassword'),
  loading: () => (<LoadableSpinner centered />),
})

export default withAuthentication(AsyncChangePassword)
