import React from 'react'
import Loadable from 'react-loadable'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import LoadableSpinner from '../../components/LoadableSpinner'
import withAuthentication from '../../HOCs/withAuthentication'
import withUser from '../../HOCs/withUser'

const AsyncPersonalDetails = Loadable({
  loader: () => import('./PersonalDetails'),
  loading: () => (<LoadableSpinner centered />),
})

const enhancePersonalDetails = compose(
  withRouter,
  withUser,
  withAuthentication
)

export default enhancePersonalDetails(AsyncPersonalDetails)
