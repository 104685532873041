import React from 'react'
import Loadable from 'react-loadable'
import LoadableSpinner from '../../components/LoadableSpinner'

const AsyncArticle = Loadable({
  loader: () => import('./Article'),
  loading: () => (<LoadableSpinner centered />),
})

export default AsyncArticle
