import css from 'styled-jsx/css'

export default css`
  .dashboard-error {
    margin: auto;
    margin-top:40px;
    padding:20px
  }

  .error-bottom{
    margin-top:40px;
  }

  .dashboard-error li{
    padding-left: 5px;
  }

  .error-bottom h3{
    margin-bottom: 25px;
  }

  .dashboard-error{
    max-width: 1000px;
  }

  .dashboard-error h1, .dashboard-error h3 {
    font-weight: 700;
  }

  .dashboard-error h1 {
    font-size: 1.4em;
  }

  @media (min-width: 767px) {
    .dashboard-error h1 {
      font-size: 2.1em;
    }
  }
`
