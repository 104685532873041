import css from 'styled-jsx/css'

export default css`

  .ResetPasswordSuccess {
    text-align: center;
    margin-top: 30%;
  }

  .big-heading {
    margin-bottom: 16px !important;
  }

  .sub-heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin-bottom: 32px !important;
    margin-left: 10px;
  }

  .success-icon {
    content: url('/img/success.png');
    margin-right: 5px;
  }

`
