import {
  getCurrentDomain,
  getEnvironment,
} from '../modules/utils'

export default async function playerPlusData(guid) {
  // TODO: Migrate to strapiApi helper
  const domain = getEnvironment(getCurrentDomain()) === 'production' ? 'stv' : 'stvqa'
  const url = `https://strapi.cms.${domain}.tv/api/i-os-plan-tiles?filters[guid]=${guid}`
  let data = ''
  // fetch player plus data from strapi
  try {
    await fetch(url)
      .then(response => response.json())
      .then((result) => {
        if (result && result.data) {
          data = result?.data?.[0]?.attributes
          return data
        }
        return null
      })
  } catch (e) {
    return null
  }
  return data
}

export async function loginSplashScreen() {
  // TODO: Migrate to strapiApi helper
  const domain = getEnvironment(getCurrentDomain()) === 'production' ? 'stv' : 'stvqa'
  const url = `https://strapi.cms.${domain}.tv/api/player-plus-splashes?filters[guid]=newUserNonPlayerPlus&populate=deep`
  let data = ''
  // fetch Login splash screen plus data from strapi
  try {
    await fetch(url)
      .then(response => response.json())
      .then((result) => {
        if (result && result.data) {
          data = result?.data[0]?.attributes
          return data
        }
        return null
      })
  } catch (e) {
    return null
  }
  return data
}
export async function getSuccessModalData() {
  // TODO: Migrate to strapiApi helper
  const domain = getEnvironment(getCurrentDomain()) === 'production' ? 'stv' : 'stvqa'
  const url = `https://strapi.cms.${domain}.tv/api/player-plus-splashes?filters[guid]=newUserPlayerPlus&populate=deep`
  let data = ''
  // fetch splash screen plus data from strapi
  try {
    await fetch(url)
      .then(response => response.json())
      .then((result) => {
        if (result && result.data && result.data.length > 0) {
          data = result.data[0].attributes
          return data
        }
        return null
      })
  } catch (e) {
    return null
  }
  return data
}
