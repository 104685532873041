import css from 'styled-jsx/css'
import { colors } from '../../styles'

export default css`

  .stv-form-input-wrapper {
    width: 100%;
    margin-bottom: 4px;
    display: inline-block;
    vertical-align: top;
    position: relative;
  }
.input-wrapper{
  height: 58px;
}
.small-text-wrapper{
width: 343px;
height: auto;
}  .input-wrapper{
    height: 58px;
  }
  .stv-form-input-wrapper:nth-child(2n) {
    margin-right: 0px;
  }

  .password-show.adjust-show-hide {
    top: 23px;
  }

  .stv-form-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px 8px 16px;
    gap: 10px;
    border: 0;
    border-radius: 8px;
    width: 100%;
    height: 58px;
    padding-top: 18px;

    background: ${colors.ctvBg};

    font-weight: 400;
    font-size: 17px;
    line-height: 17px;
    color: white;
  }

  .stv-form-input.name {
    width: -moz-available;
    width: -webkit-fill-available;
    width: fill-available;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active  {
      -webkit-box-shadow: 0 0 0 60px ${colors.ctvBg} inset !important;
      background-color: ${colors.ctvBg} !important;
      caret-color: white;
      -webkit-text-fill-color: ${colors.primaryText} !important;
      background-clip: content-box !important;
  }

  input:focus {
    background: ${colors.ctvBg};
    outline: none !important;
  }

  input.has-error:focus {
    border: 1px solid ${colors.red};
  }

  input.email-error {
    border: 1px solid ${colors.red};
  }

  .top-placeholder {
    cursor: text;
    position: relative;
    //top: 17px;
    left: 16px;
    bottom: 40px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 17px;
    /* identical to box height, or 100% */

    display: flex;
    align-items: center;

    transition: top .3s;
  }

  input:focus ~ .top-placeholder {
    position: relative;
    margin-top: -12px;
    left: 16px;

    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    color: ${colors.secondaryText};
  }

  .top-placeholder.touched {
    position: relative;
    margin-top: -12px;
    left: 16px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: ${colors.secondaryText};
  }

  .stv-form-input::placeholder {
    color: ${colors.secondaryText};
    opacity: 0;
  }
  input:focus::placeholder {
    opacity: 1;
  }
  input::-webkit-contacts-auto-fill-button,
  input::-webkit-credentials-auto-fill-button {
     visibility: hidden;
     display: none !important;
     pointer-events: none;
     height: 0;
     width: 0;
     margin: 0;
  }

  .stv-form-label {
    display: inline-block;
    font-weight: 600;
    font-size: 1em;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .stv-form-input-tv-code {
    color: ${colors.primaryText};
  }

  .tooltip-wrapper {
    width: 50%;
    display: inline-block;
    margin-left: 10px;
    margin-bottom: 6px;
  }

  .stv-form-login {
    margin-bottom: 20px;
  }

  .stv-form-input-reset-password {
    margin-top: 0;
  }

  .password-show {
    position: absolute;
    top: 0;
    right: 40px;
    content: url('/img/eye-gray-password.svg')
  }

  .password-show.hide {
    content: url('/img/eye-gray-password-shown.svg')
  }

  @media (min-width: 767px){

    .stv-form-input-tv-code {

    }
    .stv-form-input-wrapper{
      width: 343px;
    }
    .stv-form-label {
      font-size: 1.2em;
      font-weight:600;
      color: ${colors.primaryText};
    }
    .input-wrapper{
      height: 58px;
    }
    .small-text-wrapper{
    width: 343px;
    height: auto;
    }
    .email-wrapper {
      width: 340px;
      margin: 0px 0px 25px 0px;
    }

    .activate-code-wrapper {
      width: 100%;
      margin: 0px 20px 25px 0px;
    }

    .personal-details-email-wrapper {
      width:100%;
    }

    .password-show {
      right: 12px;
    }

    .stv-form-input {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 16px 16px 0px 16px;
      gap: 10px;
      border: 0;

      width: 340px;
      height: 58px;
      left: 20px;
      top: 20px;

      background: ${colors.ctvBg};

      font-weight: 400;
      font-size: 17px;
      line-height: 17px;
      color:white;
    }

    .stv-form-input.enlarge-text {
      font-size: 20px;
      line-height: 24px;
      padding: 16px;
    }
  }
  @media (max-width: 375px){
    .small-text-wrapper {
      width: 310px;
  }
  }
  @media (max-width : 375px) // or 213.4375em
  and (max-height : 600px) // or 41.6875em
{
  .password-show {
    right: 10px;
  }

  .small-text-wrapper {
    width: 303px;
  }
}

`
