import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import CookieStatementStyles from './styles'

const CookieStatement = ({ padTop, android }) => {
  let StvAndYouLink = '/terms-of-use'
  let PrivacyLink = '/terms-of-use/#privacy'
  let CookiePolicyLink = '/terms-of-use/#cookies'

  if (android) {
    StvAndYouLink = 'https://player.stv.tv/android/terms-rebrand/'
    PrivacyLink = 'https://player.stv.tv/android/terms-rebrand/#privacy'
    CookiePolicyLink = 'https://player.stv.tv/android/terms-rebrand/#cookies'
  }

  return (
    <Fragment>
      <p className={`stv-input-description ${padTop ? 'pad-top' : ''}`}>By continuing, you agree to our <a href={StvAndYouLink} rel="noreferrer noopener" target="_blank" className="no-underline">Terms of Use</a>, and confirm you've read and understood our <a href={PrivacyLink} rel="noreferrer noopener" target="_blank" className="no-underline">Privacy</a> and <a href={CookiePolicyLink} rel="noreferrer noopener" target="_blank" className="no-underline">Cookie</a> policies.</p>
      <style jsx>{CookieStatementStyles}</style>
    </Fragment>
  )
}

CookieStatement.propTypes = {
  padTop: PropTypes.bool,
  android: PropTypes.bool,
}

CookieStatement.defaultProps = {
  padTop: false,
  android: false,
}

export default CookieStatement
