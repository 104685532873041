import { connect } from 'react-redux'
import { setUser, clearUser } from '../../actions/user_actions'

const mapStateToProps = state => ({
  user: state.user,
})

const mapDispatchToProps = {
  setUser,
  clearUser,
}

export default function withUser(WrappedComponent) {
  return connect(mapStateToProps, mapDispatchToProps)(WrappedComponent)
}
