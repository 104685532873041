import React from 'react'
import { compose } from 'redux'
import Loadable from 'react-loadable'
import LoadableSpinner from '../../components/LoadableSpinner'
import withUser from '../../HOCs/withUser'
import withAuthentication from '../../HOCs/withAuthentication'

const AsyncMarketing = Loadable({
  loader: () => import('./Marketing'),
  loading: () => (<LoadableSpinner centered />),
})

const enhanceMarketing = compose(
  withAuthentication,
  withUser
)

export default enhanceMarketing(AsyncMarketing)
