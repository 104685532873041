import React from 'react'
import PropTypes from 'prop-types'

import IconLinkStyles from './styles'

const makeIconLink = (Icon) => {
  const IconLink = ({ className, active, ...passthrough }) => (
    <a
      {...passthrough}
      className={[
        'IconLink',
        active && 'active',
        className,
      ].filter(Boolean).join(' ')}
    >
      <Icon />
      <style jsx>{IconLinkStyles}</style>
    </a>
  )

  IconLink.propTypes = {
    className: PropTypes.string,
    active: PropTypes.bool,
  }

  IconLink.defaultProps = {
    className: '',
    active: false,
  }

  return IconLink
}

export default makeIconLink
