import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { compose } from 'redux'
import Loadable from 'react-loadable'
import LoadableSpinner from '../../components/LoadableSpinner'
import withUser from '../../HOCs/withUser'

const AsyncLogin = Loadable({
  loader: () => import('./Login'),
  loading: () => (<LoadableSpinner centered />),
})

const LoginContainer = (props) => {
  const { user } = props
  const [state, setState] = useState({
    initialRender: false,
    optIn: false,
  })

  useEffect(() => {
    setInitialRenderFlag()
  }, [])

  const setInitialRenderFlag = () => {
    setState({
      initialRender: true,
    })
  }

  const shouldRedirect = !state.initialRender && user && user.sub

  return (
    (shouldRedirect)
      ? <Redirect to="/personal-details" />
      : <AsyncLogin {...props} />
  )
}

LoginContainer.propTypes = {
  user: PropTypes.oneOfType([
    PropTypes.shape({
      sub: PropTypes.string,
    }),
    PropTypes.bool,
  ]).isRequired,
}

const enhanceLoginContainer = compose(withUser)

export default enhanceLoginContainer(LoginContainer)
